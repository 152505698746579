
import {computed, defineComponent, ref} from "vue";
import {
  IonCol,
  IonLabel,
  IonRow,
  IonItem, IonGrid, isPlatform,
} from "@ionic/vue";
import {ExitDelivery, ExitDeliveryItem} from "@/domain/model/delivery/delivery.model";
import {useStore} from "vuex";
import {Order} from "@/domain/model/order/order.model";
import {CreateExitDelivery, CreateExitDeliveryItem} from "@/domain/command/delivery/delivery.command";


export default defineComponent({
  name: "CheckCreateExitDeliveryItemComponent",
  components: {
    IonRow,
    IonLabel,
    IonCol,
    IonItem,
    IonGrid
  },
  props: {
    exitDelivery: null,
  },
  setup(props: any) {
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));
    const store = useStore();
    const commandItem = ref<CreateExitDelivery>(props.exitDelivery);
    const getOrder = (itemId: string): Order => store.getters.orderById(itemId);

    return {
      isTablet,
      commandItem,
      getOrder
    };
  }
})
